import { all, call, fork, put, takeEvery, select } from "redux-saga/effects";
import { VEHICLE_SELECT_ALL_REQUEST, VEHICLE_UPDATE_DETAILS } from "../actions";

import { vehicleSelectAllSuccess } from "./actions";
import { authApiAccessCheck } from "../auth/actions";

import { VehicleSelectByToken, VehicleUpdate } from "../../api-tasks/vehicles";

import {
  optionsVehicleType,
  DEFAULT_VEHICLE_TYPE_ICON
} from "../../data-const/vehicle-types";
import { optionsManufactures } from "../../data-const/manufactures";
import moment from "moment";

function getTypeIcon(vehicle) {
  try {
    const vehicleType = optionsVehicleType.filter(
      typeItem => typeItem.key === vehicle.vehicle_type
    );

    return { typeIcon: vehicleType[0].icon, typeText: vehicleType[0].value };
  } catch (error) {
    return DEFAULT_VEHICLE_TYPE_ICON;
  }
}

function getManufactureIcon(vehicle) {
  try {
    const vehicleManufacture = optionsManufactures.filter(
      typeItem => typeItem.value === vehicle.manufacture_name
    );
    return vehicleManufacture[0].icon;
  } catch (error) {}

  return "";
}

function DistinctRecords(theJson) {

  try {
    return [...new Set(theJson.map(({project})=>project))];
  } catch (error) {
    return [];
  }
}

function* selectAll() {
  const state = yield select();

  try {
    const authUser = state.authUser;
    const user = authUser.user;

    const vehicles = yield call(VehicleSelectByToken, user.userToken);

    if (vehicles) {
      if (Array.isArray(vehicles)) {
        // const reminigDays = ;

        let vehiclesWithIcon = vehicles.map(item => ({
          ...item,
          ...getTypeIcon(item),
          manufactureIcon: getManufactureIcon(item),
          key: item.autoid,
          item : item,
          project: item.project ? item.project : "",
          reminigDays : moment(item.expiry_date).diff(moment(), "days")
        }));

        const clusters = DistinctRecords(vehicles);

        yield put(vehicleSelectAllSuccess(vehiclesWithIcon, clusters));
      } else {
        // TODO : Validate Response here
        // AuthHelperAccessCheck(res);
      }
    }
  } catch (error) {
    console.log("error vehicle saga 1 :", error);
  }
}

function* update({ payload }) {
  const { history, newDetails, vehicle, callback } = payload;
  try {
    const state = yield select();

    const authUser = state.authUser;
    const user = authUser.user;

    const inputValues = {
      vehicleId: vehicle.autoid,
      title: newDetails.title,
      plateNumber: newDetails.plateNumber,
      fleetNumber: newDetails.fleetNumber,
      manufactureName: newDetails.selectedManufacture.value,
      model: newDetails.selectedModel,
      clusterProject: newDetails.clusterProject || "",
      tyre: newDetails.tyre,
      fuelType: newDetails.selectedFuelType.value,
      engine: newDetails.engine,
      vehicleType: newDetails.selectedVehicleType.key,
      year: newDetails.year,
      sim: newDetails.sim,
      sim_provider: newDetails.simProvider,
      userToken: user.userToken
    };

    
    const apiResponse = yield call(VehicleUpdate, inputValues);
    if (apiResponse && apiResponse.status === "success") {
      yield* selectAll();
    }

    yield put(authApiAccessCheck(history, apiResponse));

    if (callback) {
      callback(apiResponse);
    }
  } catch (error) {
    console.log('error saga.vehicles.update', error);
    if (callback) {
      callback({status:"error", message: JSON.stringify(error)});
    }
  }
}

export function* watchSelectAllRequest() {
  yield takeEvery(VEHICLE_SELECT_ALL_REQUEST, selectAll);
}

export function* watchUpdate() {
  yield takeEvery(VEHICLE_UPDATE_DETAILS, update);
}

export default function* rootSaga() {
  yield all([fork(watchSelectAllRequest), fork(watchUpdate)]);
}
