import React, { Component } from "react";
import { Button, Row, Card, CardBody, CardTitle, Badge } from "reactstrap";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

import { Colxx } from "../../components/common/CustomBootstrap";
import VehicleSelectWithBatch from "../../components/VehicleSelectWithBatch";
import { authApiAccessCheck } from "../../redux/actions";
import { LiveSelectByToken } from "../../api-tasks/live";
import LocationName from "../../components/LocationName";
import { NotificationManager } from "../../components/common/react-notifications";
import { AppHelperDateAndTimeToString } from "../../helpers/AppHelper";
import IntlMessages from "../../helpers/IntlMessages";
import AppLocale from "../../lang";
import { createIntl } from "react-intl";
const pagingLimit = -1;
const THREE_MIN = 3 * 60 * 1000;
// const FIFTEEN_MIN = 15 * 60 * 1000;
// const TWO_DAYS = 56 * 60 * 60 * 1000;

const headers = [
  { label: "Plate", key: "plateNumber" },
  { label: "Asset", key: "assetNumber" },
  { label: "Cluster", key: "cluster" },
  { label: "Model", key: "model" },
  { label: "GpsTime", key: "gpsTime" },
  { label: "GeoPoint", key: "geoPoint" },
  { label: "Fuel", key: "fuel" },
  { label: "UpdateTime", key: "updateTime" },
  { label: "Driver name", key: "driverName" },
  { label: "Ignition", key: "ignition" },
  { label: "Status", key: "status" },
  { label: "Device Id", key: "deviceId" },
  { label: "Sim Provider", key: "simProvider" },
  { label: "Sim No", key: "sim" },
  { label: "Sim No2", key: "sim2" },
  { label: "Installation", key: "installation" },
  { label: "Expiry", key: "expiry" },
  { label: "Reminig Days", key: "reminigDays" },
  { label: "Renewal Interval", key: "renewalIn" },
  { label: "Groups", key: "groupNames" },
];

function connectedStatus(updateTime, noDataTime) {
  if (!updateTime) return "-";
  if (new Date() - new Date(updateTime) < noDataTime) return "Connected";
  return "Disconnected";
}

function liveStatus(ignition, updateTime) {
  if (!updateTime) return "-";
  if (ignition && new Date() - new Date(updateTime) < THREE_MIN) return "On";
  return "Off";
}

const sortBy = (field, reverse, primer) => {
  const key = primer
    ? function (x) {
        return primer(x[field]);
      }
    : function (x) {
        return x[field];
      };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    // eslint-disable-next-line no-sequences
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
  };
};

class Trips extends Component {
  constructor(props) {
    super();
    this.liveMapNoDataInMillisecond = 2880 * 60 * 1000;

    this.state = {
      selectedVehicles: [],
      loading: false,
      allRows: [],
      totalCount: 0,
      touched: false,
      limit: pagingLimit,
      skip: 0,
      fileName: "Ctraac - trips.csv",
    };
  }

  componentWillUnmount() {}

  componentDidMount() {
    if (this.props.subscriberDetails) {
      const attributes = this.props.subscriberDetails.attributes;
      const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
      this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.subscriberDetails !== prevProps.subscriberDetails) {
      const attributes = this.props.subscriberDetails.attributes;
      const noDataMin = parseInt(attributes.liveMap.noDataMin, 10);
      this.liveMapNoDataInMillisecond = noDataMin * 60 * 1000;
    }
  }

  handleOnSelectedVehicleChange = (selectedVehicles) => {
    this.setState({ selectedVehicles });

    this.setState({
      selectedVehicles,
      allRows: [],
      limit: pagingLimit,
      skip: 0,
    });

    setTimeout(() => {
      this.onDateRangeGo(this.state.limit, this.state.skip);
    }, 10);
  };

  findLiveStatus = (vehicleId, response) => {
    const foo = response.filter(function (x) {
      return x.vehicle_id === vehicleId;
    });

    return foo[0] || {};
  };

  onDateRangeGo = () => {
    if (!this.state.selectedVehicles.length) {
      NotificationManager.error(
        "please select vehicle",
        "ERROR",
        3000,
        null,
        null,
        ""
      );

      return;
    }

    const user = this.props.user;
    const vehicles = this.state.selectedVehicles;

    this.setState({ loading: true });

    LiveSelectByToken(user.userToken).then((response) => {
      this.setState({
        loading: false,
        fileName: `Ctraac - Live status ${moment().format(
          "YYY-MM-DD hh:mm A"
        )}.csv`,
      });
      const groupList = this.props.groups || [];
      if (response && Array.isArray(response)) {
        const allRows = vehicles.map((x) => {
          const foo = this.findLiveStatus(x.key, response);
          

          const gList = [];
          groupList.forEach(gItem => {
            if (gItem.vehicleData.indexOf(x.key.toString()) !== -1) {
              gList.push(gItem);
              // console.log('a lot of ', gItem);
            }
          });

          return {
            webId: x.key,
            plateNumber: x.item.plate_number,
            assetNumber: x.item.fleet_number,
            cluster: x.item.project,
            model: x.item.model,
            gpsTime: AppHelperDateAndTimeToString(foo.gpstime),
            geoPoint: foo.lat ? `${foo.lat},${foo.lng}` : "",
            fuel: `${foo.fuel > 0 ? foo.fuel : "-"}`,
            updateTime: AppHelperDateAndTimeToString(foo.updatetime),
            timeStamp: new Date(foo.updatetime),
            driverName: this.findDriverName(foo),
            ignition: liveStatus(foo.ignition, foo.gpstime),
            status: connectedStatus(
              foo.updatetime,
              this.liveMapNoDataInMillisecond
            ),
            manufacture_name: x.item.manufacture_name,
            manufactureIcon: x.item.manufactureIcon,
            typeText: x.typeText,
            typeIcon: x.typeIcon,
            deviceId: x.item.device_id,
            simProvider: x.item.sim_provider,
            sim: x.item.sim,
            sim2: `${x.item.sim} #`,
            installation: moment(x.item.created_date).format("DD MMM yyyy"),
            expiry: moment(x.item.expiry_date).format("DD MMM yyyy"),
            reminigDays: x.item.reminigDays,
            renewalIn: x.item.re_newal_interval,
            groupList: gList,
            groupNames: (gList.map((g) => g.group_name)).join(', ')
          };
        });

        const sortedRows = allRows.sort((a, b) => b.timeStamp - a.timeStamp);

        this.setState({
          allRows: sortedRows,
          totalCount: response.length,
          touched: true,
        });
      }
    });
  };

  findDriverName = (item) => {
    const ignition = item || {};
    if (ignition.driver_id && ignition.driver_id > 0) {
      if (this.props.driversAll && this.props.driversAll.length) {
        const selectedDriver = this.props.driversAll.filter(function (value) {
          return value.autoid === ignition.driver_id;
        });

        if (selectedDriver && selectedDriver.length) {
          return `${selectedDriver[0].driver_name}-${selectedDriver[0].employee_code}`;
        }
      }
    }
    return "";
  };

  render() {
    const currentAppLocale = AppLocale[this.props.locale];
    // Create the `intl` object
    const intl = createIntl({
      // Locale of the application
      locale: currentAppLocale.locale,
      // Locale of the fallback defaultMessage
      defaultLocale: "en",
      messages: currentAppLocale.messages,
    });
    window.localsss = intl;
    return (
      <React.Fragment>
        <Row className="mb-1">
          <Colxx xxs="12">
            <Card>
              <CardBody className={this.state.loading ? "wait" : ""}>
                <Row className="mb-1">
                  <Colxx xxs="12">
                    <VehicleSelectWithBatch
                      classNamePrefix="repo-sel"
                      onChange={this.handleOnSelectedVehicleChange}
                      allowExpired={true}
                    />
                  </Colxx>
                </Row>
                <Row>
                  <Colxx xxs="2">
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() =>
                        this.onDateRangeGo(this.state.limit, this.state.skip)
                      }
                    >
                      {this.state.loading ? (
                        <span className="spinner d-inline-block">
                          <span className="bounce1" />
                          <span className="bounce2" />
                          <span className="bounce3" />
                        </span>
                      ) : (
                        <IntlMessages id="common.refresh" />
                      )}
                    </Button>
                  </Colxx>
                </Row>
              </CardBody>
            </Card>
          </Colxx>
        </Row>

        <Row>
          <Colxx xxs="12">
            <Card>
              <CardBody>
                {this.state.selectedVehicles.length && this.state.touched ? (
                  <CardTitle>
                    {intl.formatMessage({
                      id: "report.live-status",
                    })}
                    {AppHelperDateAndTimeToString(moment())}
                  </CardTitle>
                ) : (
                  <CardTitle>
                    {" "}
                    {intl.formatMessage({
                      id: "report.try-search",
                    })}{" "}
                  </CardTitle>
                )}

                {this.state.touched && !this.state.totalCount ? (
                  <p>
                    {" "}
                    {intl.formatMessage({
                      id: "report.nothing-show",
                    })}
                  </p>
                ) : (
                  ""
                )}
                <div className="card-body-min-height">
                  <div className="float-right">
                    <Button
                      className="d-none"
                      title="the sort"
                      size="xs"
                      outline
                      onClick={() => {
                        this.setState({
                          allRows: this.state.allRows.sort(
                            sortBy("status", false, (a) => a.toUpperCase())
                          ),
                        });
                      }}
                      color="info"
                    >
                      {intl.formatMessage({
                        id: "report.sort",
                      })}
                    </Button>

                    <CSVLink
                      headers={headers}
                      filename={this.state.fileName}
                      data={this.state.allRows}
                      enclosingCharacter={`'`}
                    >
                      <Button
                        title={this.state.fileName}
                        size="xs"
                        outline
                        color="dark"
                      >
                        <IntlMessages id="common.download" />
                      </Button>
                    </CSVLink>
                  </div>
                  {this.state.allRows.length +
                    " " +
                    intl.formatMessage({
                      id: "report.records",
                    })}

                  <Row className={"mt-2"}>
                    <Colxx xxs="12"></Colxx>
                  </Row>

                  {this.state.allRows.map((item, i) => (
                    <Row
                      key={this.state.skip + i + 1}
                      className={`border-bottom p-2 ${i % 2 ? `bg-light` : ``}`}
                    >
                      <Colxx xxs="12">
                        {this.state.skip + i + 1}
                        <span className="float-right">#{item.webId}</span>

                        <span className="d-inline-block p-1">
                          <img
                            title={item.manufacture_name}
                            className="app-select-icon"
                            src={item.manufactureIcon}
                            alt="icon"
                          />
                          <img
                            title={item.typeText}
                            className="app-select-icon"
                            src={item.typeIcon}
                            alt="icon"
                          />

                          {`${item.plateNumber} ${item.assetNumber} ${item.model} ${item.cluster}`}
                          
                          
                          
                          
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>GPS:</b>
                            {item.gpsTime}
                            {" | "}
                            <LocationName
                              place={item.place}
                              geo={item.geoPoint}
                              objectItem={item}
                              showGoogle
                            />
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Last update:</b>
                            {item.updateTime}
                          </u>
                        </span>
                        <span className="p-1">
                          <u>
                            <b>Device:</b>
                            {item.deviceId}
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Ignition:</b>
                            {item.ignition}
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Sim:</b>
                            {item.sim}
                            {" | "}
                            {item.simProvider}
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Fuel:</b>
                            {item.fuel}
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Driver:</b>
                            {item.driverName}
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Installation:</b>
                            {item.installation}
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Expiry:</b>
                            {`${item.expiry} | ${item.renewalIn} | ${item.reminigDays}`}
                            
                            
                          </u>
                        </span>

                        <span className="d-inline-block p-1">
                          <u>
                            <b>Groups:</b>
                             
                            
                            {item.groupNames}
                            
                          </u>
                        </span>

                        {item.reminigDays < 0 ? (
                          <span className="mb-1 mr-1 bg-yellow p-1">
                           
                             Expired since {Math.abs(item.reminigDays)} days
                             
                          </span>
                        ) : null}
                        <Badge
                          color={
                            item.status === "Connected" ? `primary` : `warning`
                          }
                          className="mb-1 mr-1"
                        >
                          {item.status}
                        </Badge>


                        
                
                      </Colxx>
                    </Row>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Colxx>
        </Row>
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({
  vehicles,
  authUser,
  drivers,
  subscriber,
  settings,
  groups
}) => {
  const { all } = vehicles;
  const { user } = authUser;
  const { items } = drivers;
  const { details } = subscriber;
  const { locale } = settings;
  return {
    vehiclesAll: all,
    user,
    driversAll: items,
    subscriberDetails: details,
    locale,
    groups: groups.items,
  };
};

export default connect(mapStateToProps, { authApiAccessCheck })(Trips);
