import { VEHICLE_SELECT_ALL_SUCCESS, VEHICLE_STATE_DISPOSAL } from "../actions";

const INIT_STATE = {
  all: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case VEHICLE_SELECT_ALL_SUCCESS:
      return {
        ...state,
        all: action.payload.vehicles,
        clusters: action.payload.clusters
      };
    case VEHICLE_STATE_DISPOSAL:
      return { ...state, all: [] };
    default:
      return { ...state };
  }
};
