import jwt_decode from "jwt-decode";
import { AuthHelperGerSubscriberRoleText } from "../../businessjs/AuthHelper";

import {
  LOGIN_USER,
  LOGIN_USER_AUTO,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_DETAILS,
  LOGIN_USER_REJECTED,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGOUT_USER
} from "../actions";

function loadLocalStorage(userDetails) {
  try {
    const userKey = userDetails.token;

    if (userKey) {
      return {
        userToken: userKey
      };
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}

function extractedInfo(token, loadType) {
  try {
    const decoded = jwt_decode(token);
    const decodedUser = decoded.userAccessKey[0];

    return {
      executiveId: decodedUser.autoid,
      token: token,
      subscriberId: decodedUser.subscriber_id,
      firstName: decodedUser.first_name,
      lastName: decodedUser.last_name,
      email: decodedUser.email,
      accountType: decodedUser.account_type,
      companyName: decodedUser.company_name,
      loginType: decoded.accessType,
      userType: decoded.accessType,
      role: decodedUser.role,
      roleText: AuthHelperGerSubscriberRoleText(decodedUser.role),
      loadType: loadType
    };
  } catch (e) {
    return {
      executiveId: null,
      token: "",
      subscriberId: "",
      firstName: "",
      lastName: "",
      email: "",
      accountType: "",
      companyName: "",
      loginType: "",
      userType: "",
      role: "",
      roleText: "",
      loadType: "exception default"
    };
  }
}

const userDetails = extractedInfo(
  localStorage.getItem("user_id"),
  "localMemory"
);

const INIT_STATE = {
  user: loadLocalStorage(userDetails),
  details: userDetails,
  loading: false,
  offsetMinutes: 180
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_USER_AUTO:
      return { ...state };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
        details: extractedInfo(action.payload.userToken, "loginSuccess")
      };
    case LOGIN_USER_REJECTED:
      return { ...state, loading: false };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid };
    case LOGOUT_USER:
      return { ...state, user: null, details: extractedInfo("", "logOut") };
    case LOGIN_USER_DETAILS:
      return { ...state };
    default:
      return { ...state };
  }
};
