import { SUBSCRIBER_SELECT_SUCCESS } from "../actions";

const INIT_STATE = {
    details: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SUBSCRIBER_SELECT_SUCCESS:
      return { ...state, details: action.payload.subscriber };
      
    default:
      return { ...state };
  }
};
